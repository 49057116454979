import { AnyAction } from 'redux';
import { ModalState } from '../../interfaces/ModalState';
import { ModalActionTypes } from '../actionTypes';
import theme from '../../themes/default';

export const initialState: ModalState = {
  isOpen: false,
  mfIdentifier: '',
  additionalCloseProps: {},
  modalOptions: {
    enableBackdrop: true,
    backgroundColor: theme.colors.container.background,
    maxWidth: null,
    maxHeight: null,
    align: 'center',
    alignOffset: 15,
    noPadding: true,
    topOffset: RSIUtils.detector.isDesktop ? 115 : 60,
    compactDraggableArea: true,
    draggableAreaWidth: 40,
  },
};

export default (
  state = initialState,
  { type, payload }: AnyAction = { type: ModalActionTypes.DEFAULT },
) => {
  switch (type) {
    case ModalActionTypes.OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
      };
    case ModalActionTypes.CLOSE_MODAL:
      return {
        ...state,
        additionalCloseProps: payload,
        isOpen: false,
      };
    default:
      return state;
  }
};
