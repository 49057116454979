/* eslint-disable import/prefer-default-export */
import { ModalActionTypes } from '../actionTypes';

export const openModalAction = () => ({
  type: ModalActionTypes.OPEN_MODAL,
});

export const closeModalAction = (additionalCloseProps: Object = {}) => ({
  type: ModalActionTypes.CLOSE_MODAL,
  payload: additionalCloseProps,
});
