const colorPalette = {
  white: '#FFFFFF',
  black: '#000000',
};

export default {
  colors: {
    container: {
      background: colorPalette.white,
    },
  },
  borderRadius: {
    container: '20px',
  },
};
