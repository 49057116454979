import React from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import useLocale from './hooks/useLocale';
import useTimeZone from './hooks/useTimeZone';
import defaultTheme from './themes/default';
import { ModuleProps } from './interfaces';

import BottomModalSheet from './components/BottomModalSheet/BottomModalSheet';

function App({
  onModalInit,
  selfDestroy,
}: ModuleProps) {
  const timeZone = useTimeZone();
  const locale = useLocale();

  if (!locale || !timeZone) {
    return null;
  }

  return (
    <IntlProvider timeZone={timeZone} locale={locale} defaultLocale={locale}>
      <ThemeProvider theme={defaultTheme}>
        <BottomModalSheet
          onModalInit={onModalInit}
          selfDestroy={selfDestroy}
        />
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
