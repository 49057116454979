import React, { useEffect, useState, useCallback } from 'react';
import Sheet from 'react-modal-sheet';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { modalActions } from '../../store/actions';
import useAnimationFrame from '../../utils/useAnimationFrame';
import { RootState } from '../../interfaces';
import { logger } from '../../services';

const {
  REACT_APP_CONTAINER_ID,
  REACT_APP_IDENTIFIER,
} = process.env;

interface ModalSheetProps {
  $backgroundColor?: string;
  $maxHeight?: number | null;
  $maxWidth?: number | null;
  $align?: 'left' | 'right' | 'center';
  $alignOffset?: number;
  $topOffset?: number;
  $noPadding?: boolean;
  $enableBackdrop?: boolean;
  $compactDraggableArea?: boolean;
  $draggableAreaWidth?: number;
}

interface BottomModalSheetProps {
  onModalInit?: () => void,
  selfDestroy: (id: string) => void,
}

const ModalSheet = styled(Sheet) <ModalSheetProps>`
  ${({ $maxWidth }) => $maxWidth && `
    max-width: ${$maxWidth}px;
  `}

  ${({ $align }) => $align === 'center' && `
      margin: 0 auto;
  `}

  ${({ $align, $alignOffset }) => $align === 'left' && `
      margin: ${`0 auto 0 ${$alignOffset}px`};
  `}

  ${({ $align, $alignOffset }) => $align === 'right' && `
      margin: ${`0 ${$alignOffset}px 0 auto`};
  `}

  .react-modal-sheet-header {
    ${({ $noPadding }) => $noPadding && `
      position: absolute !important;
      top: 0;
      left: 0;
      z-index: 100;
    `}

    ${({ $noPadding, $compactDraggableArea, $draggableAreaWidth }) => $noPadding && $compactDraggableArea && `
      width: ${$draggableAreaWidth}% !important;
      left: ${50 - ($draggableAreaWidth || 100) / 2}%;
    `}
  }

  .react-modal-sheet-container {
    background-color: ${({ $backgroundColor }) => $backgroundColor} !important;
    border-top-left-radius: ${({ theme }) => theme.borderRadius.container} !important;
    border-top-right-radius: ${({ theme }) => theme.borderRadius.container} !important;
    ${({ $maxHeight }) => $maxHeight !== null && `
      height: ${$maxHeight}px !important;
    `}

    ${({ $maxHeight, $topOffset }) => $maxHeight === null && `
      height: calc(100% - env(safe-area-inset-top) - ${$topOffset}px) !important;
    `}

    ${({ $enableBackdrop }) => !$enableBackdrop && `
      box-shadow: none !important;
    `}

    & > div {
      transform: none !important;
    }
  }
`;

const SheetContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.container};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.container};
`;

function BottomModalSheet({
  onModalInit,
  selfDestroy,
}: BottomModalSheetProps) {
  const dispatch = useDispatch();
  const modalState = useSelector((state: RootState) => state.modal);
  const [startAnimation, setStartAnimation] = useState(false);
  const {
    isOpen,
    mfIdentifier,
    additionalCloseProps,
    modalOptions,
  } = modalState;

  const handleModalClose = useCallback(() => {
    dispatch(modalActions.closeModalAction());
  }, [dispatch]);

  const handleModalCloseEnd = useCallback(() => {
    RSIUtils.unlockBodyScroll();
    /**
     * @deprecated This event should not be used
     * Please use BOTTOM_SHEET_MODAL_CLOSED instead
     * TODO: PBO-1054 Deprecate Init and Destroy MF events
     */
    RSIEventBus.publish(
      RSIEventBus.eventTypes.DESTROY_MF_BOTTOM_SHEET_MODAL,
      {
        identifier: mfIdentifier,
        containerId: REACT_APP_CONTAINER_ID,
      },
      { withHistory: true },
    );

    UtilEventBus.publish(
      UtilEventBus.eventTypes.BOTTOM_SHEET_MODAL_CLOSED,
      {
        identifier: mfIdentifier,
        containerId: REACT_APP_CONTAINER_ID,
        ...additionalCloseProps,
      },
      { withHistory: true },
    );

    logger.info('Bottom modal sheet closed', { identifier: mfIdentifier });
    selfDestroy(REACT_APP_IDENTIFIER as string);
  }, [mfIdentifier, additionalCloseProps, selfDestroy]);

  const handleModalOpenStarts = useCallback(() => {
    RSIUtils.lockBodyScroll();

    if (onModalInit) {
      onModalInit();
    }
  }, [onModalInit]);

  const openModal = () => {
    dispatch(modalActions.openModalAction());
    setStartAnimation(false);
  };

  useAnimationFrame(openModal, startAnimation);

  // Open the modal by default
  useEffect(() => {
    setStartAnimation(true);
  }, []);

  // We use this to conditionally add the backdrop element
  // the react-modal sheet uses React.CloneElement so we can't send null
  const getModalElements = (): React.ReactElement[] => {
    const components: React.ReactElement[] = [];

    components.push(
      <ModalSheet.Container data-testid="modal-sheet-container" key={0}>
        <ModalSheet.Header />
        <SheetContent id={REACT_APP_CONTAINER_ID} />
      </ModalSheet.Container>,
    );

    if (modalOptions.enableBackdrop) {
      components.push(<ModalSheet.Backdrop data-testid="modal-sheet-backdrop" key={1} />);
    }

    return components;
  };

  return (
    <ModalSheet
      isOpen={isOpen}
      onClose={handleModalClose}
      onOpenStart={handleModalOpenStarts}
      onCloseEnd={handleModalCloseEnd}
      $align={modalOptions.align}
      $alignOffset={modalOptions.alignOffset}
      $backgroundColor={modalOptions.backgroundColor}
      $maxHeight={modalOptions.maxHeight}
      $maxWidth={modalOptions.maxWidth}
      $noPadding={modalOptions.noPadding}
      $topOffset={modalOptions.topOffset}
      $enableBackdrop={modalOptions.enableBackdrop}
      $compactDraggableArea={modalOptions.compactDraggableArea}
      $draggableAreaWidth={modalOptions.draggableAreaWidth}
      data-testid="modal-sheet"
    >
      {getModalElements()}
    </ModalSheet>
  );
}

BottomModalSheet.defaultProps = {
  onModalInit: null,
};

export default BottomModalSheet;
