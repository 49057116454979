import { useRef, useEffect, useCallback } from 'react';

export default (nextAnimationFrameHandler: Function, shouldAnimate: boolean = true) => {
  const frame = useRef(0);

  const animate = useCallback(() => {
    nextAnimationFrameHandler();
    frame.current = requestAnimationFrame(animate);
  }, [nextAnimationFrameHandler]);

  useEffect(() => {
    if (shouldAnimate) {
      frame.current = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(frame.current);
    }

    return () => cancelAnimationFrame(frame.current);
  }, [shouldAnimate, animate]);
};
