import React, { StrictMode, useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';
import Module, { storeBucket } from './lib';
import { InitArgs, OpenBottomSheetModalEventParams } from './lib/interfaces';
import { logger } from './lib/services';
import UMDLoader from './lib/UMDLoader';
import { modalActions } from './lib/store/actions';
import { initialState as modalInitialState } from './lib/store/reducers/modal';
import { Direction } from './lib/interfaces/ModalState';

const {
  REACT_APP_IDENTIFIER,
  REACT_APP_CONTAINER_ID,
  PUBLIC_URL,
} = process.env;

const isDevelopment = process.env.NODE_ENV === 'development';
const createsMultiInstanceModule = JSON.parse(
  process.env.REACT_APP_CREATES_MULTI_INSTANCE_MODULE as string,
);
const providesVanillaModule = JSON.parse(process.env.REACT_APP_PROVIDES_VANILLA_MODULE as string);

const canIncludeUMDModule = !providesVanillaModule
  && JSON.parse(process.env.REACT_APP_INCLUDES_CLIENT_FROM_UMD as string);

let sourceUrl = PUBLIC_URL;
if (isDevelopment) {
  sourceUrl = `http://localhost:${process.env.REACT_APP_PORT}`;
}

const version = process.env.REACT_APP_VERSION;

interface ModuleProps extends InitArgs {
  containerId: string;
  selfDestroy: (id: string) => void;
}

function AppWithCallbackAfterRender({
  initialState,
  containerId,
  onModalInit,
  selfDestroy,
}: ModuleProps) {
  useEffect(() => {
    logger.trace('Client initialized', { containerId });
  }, [containerId]);

  return (
    <StrictMode>
      {canIncludeUMDModule ? (
        <UMDLoader
          url={`${sourceUrl}/${REACT_APP_IDENTIFIER}.umd.buildtype.js`}
          name={process.env.REACT_APP_TAG as string}
          props={{ initialState }}
        />
      ) : (
        <Module
          initialState={initialState}
          onModalInit={onModalInit}
          selfDestroy={selfDestroy}
        />
      )}
    </StrictMode>
  );
}

const instances = new Map<string, Root>();

const isSelfExecuting = isDevelopment || providesVanillaModule || !createsMultiInstanceModule;

function destroy(id: string) {
  try {
    if (!id && !isSelfExecuting) {
      throw new Error('Client is not single instance, cannot destroy client without container id');
    }

    const containerId = id || REACT_APP_IDENTIFIER as string;

    const destroyTarget = instances.get(containerId);

    if (!destroyTarget) {
      throw new Error('Client not initialized');
    }

    destroyTarget.unmount();

    instances.delete(containerId);

    logger.trace('Client destroyed', { containerId });
  } catch (error) {
    logger.error('Failed to destroy client', { error });
  }
}

function showModal() {
  storeBucket.modalStore.dispatch(modalActions.openModalAction());
}

function closeModal(additionalCloseProps: Object) {
  storeBucket.modalStore.dispatch(modalActions.closeModalAction(additionalCloseProps));
}

interface Args extends InitArgs {
  containerId?: string;
}

function init({ containerId: id, initialState, onModalInit }: Args = {}) {
  try {
    let containerId = id;

    if (!containerId) {
      logger.trace(`containerId not used to init, using default value: ${REACT_APP_IDENTIFIER}`);

      containerId = REACT_APP_IDENTIFIER as string;
    }

    const container = document.getElementById(containerId);

    if (!container) {
      throw new Error(`Could not find container ${containerId} in DOM`);
    }

    if (instances.has(containerId)) {
      throw new Error(`Client already initialized to container ${containerId}`);
    }

    const root = createRoot(container);

    root.render(
      <AppWithCallbackAfterRender
        initialState={initialState}
        containerId={containerId}
        onModalInit={onModalInit}
        selfDestroy={destroy}
      />,
    );

    instances.set(containerId, root);

    return {
      destroy() {
        if (containerId) {
          destroy(containerId);
        }
      },
    };
  } catch (error) {
    logger.error('Failed to initialize client', { error });

    return undefined;
  }
}

/**
 * Create the container element by default
 */

document.addEventListener('DOMContentLoaded', () => {
  const containerId = REACT_APP_IDENTIFIER as string;
  const container = document.getElementById(containerId);

  if (!container) {
    const containerEl = document.createElement('div');
    containerEl.setAttribute('id', containerId);
    document.body.appendChild(containerEl);
  }
});

/**
 * We subscribe to the open/close bottom sheet modal events
 * to instantiate the MFE once the modal is open and destroy it
 * once the close animation ends.
 */

if (!RSIUtils.detector.isMobileApp) {
  RSIEventBus.subscribe(
    RSIEventBus.eventTypes.OPEN_BOTTOM_SHEET_MODAL,
    ({ identifier, modalOptions, config }: OpenBottomSheetModalEventParams) => {
      const getDesktopAndTabletModalOptions = () => {
        const { isDesktop } = RSIUtils.detector;
        const alignRight = isDesktop || window.innerWidth > 766;

        if (alignRight) {
          return {
            enableBackdrop: false,
            maxWidth: 390,
            align: 'right' as Direction,
          };
        }
        return undefined;
      };

      init({
        onModalInit: () => {
          if (identifier) {
            /**
             * @deprecated This event should not be used
             * Please use BOTTOM_SHEET_MODAL_OPENED instead
             * TODO: PBO-1054 Deprecate Init and Destroy MF events
             */
            RSIEventBus.publish(
              RSIEventBus.eventTypes.INIT_MF_BOTTOM_SHEET_MODAL,
              {
                identifier,
                config,
                containerId: REACT_APP_CONTAINER_ID,
              },
              { withHistory: true },
            );

            UtilEventBus.publish(
              UtilEventBus.eventTypes.BOTTOM_SHEET_MODAL_OPENED,
              {
                identifier,
                config,
                containerId: REACT_APP_CONTAINER_ID,
              },
              { withHistory: true },
            );

            logger.info('Bottom modal sheet opened', { identifier });
          }
        },
        initialState: {
          modal: {
            ...modalInitialState,
            mfIdentifier: identifier,
            modalOptions: {
              ...modalInitialState.modalOptions,
              ...(modalOptions ?? getDesktopAndTabletModalOptions()),
            },
          },
        },
      });
    },
  );

  UtilEventBus.subscribe(
    UtilEventBus.eventTypes.CLOSE_BOTTOM_SHEET_MODAL,
    (additionalCloseProps: Object) => {
      closeModal(additionalCloseProps);
    },
  );
}

export {
  version,
  instances,
  init,
  destroy,
  showModal,
  closeModal,
};
