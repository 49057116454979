import React from 'react';
import { Provider } from 'react-redux';
import { ModuleProps } from './interfaces';
import store from './store';
import App from './App';

export const storeBucket: { [key: string]: any } = {};

function Module({
  initialState,
  onModalInit,
  selfDestroy,
}: ModuleProps) {
  const storeInstance = store(initialState);
  storeBucket.modalStore = storeInstance;

  return (
    <Provider store={storeInstance}>
      <App
        onModalInit={onModalInit}
        selfDestroy={selfDestroy}
      />
    </Provider>
  );
}

export default Module;
